import toast from 'react-hot-toast';
import { JSONParse } from '../../utils/json';

const totals = (item) => item?.reduce((previous, current) => previous + current, 0);

export const parseCustomerFields = (obj) => {
  const {
    id: customerId,
    name,
    dateCreated,
    meta,
    contacts,
    loyaltyPoint,
    storeCredit,
    createdBy,
    business: { id: businessId }
  } = obj || {};
  const { username } = createdBy || {};
  const metaObj = JSONParse(meta);
  const contactsObj = JSONParse(contacts);
  const {
    address_line_one: addressLine1,
    address_line_two: addressLine2,
    mobile_number: mobile,
    email
  } = contactsObj || {};
  const {
    region, city, country, photo, notes
  } = metaObj || {};
  const address = [addressLine1, addressLine2, region, city, country];
  return {
    customerId,
    name,
    dateCreated,
    address,
    mobile,
    email,
    businessId,
    photo,
    notes,
    loyaltyPoint,
    storeCredit,
    username
  };
};

export const parseCategoryFields = (obj) => {
  const {
    id: categoryId, categoryName, loyaltyWeighting, meta
  } = obj || {};
  const metaObj = JSONParse(meta);
  const {
    pricing_value: pricingValue,
    pricing_type: pricingType,
    reorder_point: reorderPoint,
    reorder_unit: reorderUnit,
    reorder_max: reorderMax
  } = metaObj || {};
  return {
    categoryId,
    categoryName,
    pricingValue,
    loyaltyWeighting,
    pricingType,
    reorderPoint,
    reorderUnit,
    reorderMax
  };
};

export const parseSupplierFields = (obj) => {
  const {
    id: supplierId,
    name,
    logo,
    dateCreated,
    meta,
    contacts,
    settings,
    supplierorderSet,
    business: { id: businessId }
  } = obj || {};
  const bizId = localStorage.getItem('ogarx_business_id');
  const role = localStorage.getItem('oga_user_role');
  const isOgaAdmin = role === 'oga-pharmacy-admin';
  const metaObj = JSONParse(meta);
  const contactsObj = JSONParse(contacts);
  const settingsObj = JSONParse(settings);
  const {
    address_line_one: addressLine1,
    address_line_two: addressLine2,
    mobile_number: mobile,
    primary_email: email
  } = contactsObj || {};
  const {
    region,
    city,
    country,
    provider_promo: providerPromo
  } = metaObj || {};
  const address = [addressLine1, addressLine2, region, city, country];
  const { payment_term: terms, markup = 1 } = settingsObj || {};
  const businessOrders = supplierorderSet?.filter(({ order }) => {
    if (isOgaAdmin) return order?.businessUser?.role?.name === role;
    return order?.business?.id === bizId;
  });
  const orderVolume = totals(
    businessOrders?.map(({ productCount }) => productCount)
  );
  const orderValue = totals(businessOrders?.map(({ total }) => total));

  return {
    supplierId,
    name,
    logo,
    dateCreated,
    address,
    terms,
    orderVolume,
    orderValue,
    mobile,
    email,
    businessId,
    providerPromo,
    markup
  };
};

export const cherryPickOrder = (data) => {
  const ordersWithIncompletePayment = [];
  const ordersWithoutPayment = [];
  data.supplier.supplierorderSet.forEach((supplierOrder) => {
    const { paymentSet, orderproductSet } = supplierOrder;
    if (paymentSet.length) {
      const orderAmount = orderproductSet.reduce(
        (orderProductA, orderProductB) => orderProductA
          + orderProductB.unitCost * (orderProductB.quantityRecieved || 0),
        0
      );
      const amountPaid = paymentSet.reduce(
        (paymentA, paymentB) => paymentA + paymentB.amount,
        0
      );
      if (amountPaid < orderAmount) {
        ordersWithIncompletePayment.push({
          ...supplierOrder,
          paymentOutstanding: orderAmount - amountPaid
        });
      }
    } else {
      ordersWithoutPayment.push(supplierOrder);
    }
  });

  return { ordersWithIncompletePayment, ordersWithoutPayment };
};

export const numberFormater = (num) => {
  const formatter = new Intl.NumberFormat('en');
  return formatter.format(num);
};

const identifyRoute = (identifier, role) => {
  if (identifier === 'erp' && (role === 'store-manager' || role === 'chain-manager')) return '/sales';
  if (identifier === 'setup') return '/settings/business';
  if (identifier === 'help-desk') return '/help-desk';
  if (identifier === 'account-info') return '/account-info';
  return null;
};

export const goToModule = (navigate, switchRole, allowedPermissionsMap, identifier) => {
  let role = localStorage.getItem('oga_user_role');
  const { userPermissions } = allowedPermissionsMap;
  const knownRoute = identifyRoute(identifier, role);
  if (knownRoute) navigate(knownRoute);
  else if (userPermissions?.includes('CAN_SWITCH_ROLE')) {
    switchRole({
      variables: { oldRole: role }
    }).then(({ data: { switchRole: { message, newRole } } }) => {
      let autoSelectModule = identifier;
      if (newRole) {
        localStorage.setItem('oga_user_role', newRole);
        autoSelectModule = newRole === 'affiliate-admin' ? 'oga' : 'erp';
        role = newRole;
      }
      toast.success(message);
      navigate(identifyRoute(autoSelectModule));
    }).catch((err) => {
      toast.error(err?.message);
    });
  }
};

export function kFormatter(num) {
  return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
}

export function abbreviateNumber(number) {
  if (number === 0) return '0';

  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  // const tier = Math.log10(Math.abs(number)) / 3 || 0;
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3); // Ensure it's an integer

  // if zero, we don't need a suffix
  // if (tier === 0) return number;
  if (tier <= 0) return number.toFixed(0); // Return the number as is if less than 1k

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}
