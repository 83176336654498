import {
  Grid, MenuItem, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../../customComponents/customButton';

export const FiltersGrid = styled(Grid)`
  margin-top: 20px;
  padding-bottom: 1rem;
  align-items: center;
`;

export const ButtonsGrid = styled(Grid)`
  padding: 0 1.8rem 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 1.8rem 1.8rem;
  }
`;

export const SetFilter = styled(Button)`
  width: 100%;
  height: 2.95rem;
  border-radius: .4rem;
  margin-left: 2.5rem;
  background: #235A91;
  &:hover {
    background: #235A91;
  }
`;

export const FilterText = styled(Typography)`
  font-weight: 700 !important;
  font-size: .75rem !important;
  color: #FFFFFF !important;
`;

export const FilterContainer = styled(Grid)`
  margin-top: -20px;
`;

export const MenuItems = styled(MenuItem)`
  height: 3.063rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.669rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
  &:hover {
    background-color: #F3F9FF;
  }
`;
