import React, { useState } from 'react';
import {
  Button, DialogActions, DialogContent, DialogContentText,
  Slide, Box, Grid
} from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { LINK_ACCOUNT_TO_MP } from '../../../../mutations/business';
import {
  ButtonsGrid, TitleTextGridContainer, PrimaryTitle, SecondaryTitle, CDialog
} from './processMarketplaceConnect.styles';
import CustomInputBase from '../../../customComponents/customInputBase';
import ConfirmDialog from '../confirmDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ProcessMarketplaceDialog = ({
  openConfirmation,
  handleClose,
  title,
  discreption,
  refetch
}) => {
  const initialState = {
    verificationCode: ''
  };
  const initialErrorState = {
    verificationCodeError: false
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  const toggleDialog = () => {
    setOpen(!open);
  };

  const {
    verificationCodeError,
  } = errorState;

  const topFields = [
    {
      name: 'verificationCode', label: 'Verification Code', required: true, placeholder: 'CAAC-3SDC',
      error: verificationCodeError, helperText: 'Verification Code required', show: true
    },
  ];

  const [linkMpAccount, { loading: loadingLinkMP }] = useMutation(LINK_ACCOUNT_TO_MP);
  const connectAccountToMp = (verificationCode) => {
    linkMpAccount({
      variables:
      {
        linkMpAccountData: {
          mpAccountCode: verificationCode
        }
      }
    })
      .then(({ data: { linkMpAccount: { message } } }) => {
        toast.success(message);
        setErrorCode(false);
        toggleDialog();
        handleClose();
        refetch();
      })
      .catch((err) => {
        let parsedMessage;

        try {
          parsedMessage = JSON.parse(err?.message);
        } catch {
          parsedMessage = null;
        }

        if (parsedMessage?.message) {
          toast.error(parsedMessage.message);
        } else {
          toast.error(err?.message);
        }

        setErrorCode(true);
      });
  };

  const handleSyncStore = () => {
    const {
      verificationCode
    } = state;

    if (verificationCode === '') {
      toast.error('Verification Code is required');
    } else if (verificationCode.length > 1) {
      return connectAccountToMp(verificationCode);
    }
    return setErrorState({
      ...errorState,
      verificationCodeError: verificationCode === '',
    });
  };

  const validateState = (name, value) => {
    if (name === 'verificationCode') {
      return setErrorState({
        ...errorState,
        [`${name}Error`]: !value.length || value === undefined
      });
    }
    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, placeholder, type
    } = field;
    const value = state[fieldName];
    return (
      <CustomInputBase
        value={value}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={(error ?? false) || errorCode}
        helperText={(error && helperText) || (errorCode && 'Incorrect verification code, please check again!')}
        disabled={loadingLinkMP}
        required={required}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
      />
    );
  };

  return (
    <>
      <CDialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          style={{ padding: '30px 34px' }}
        >
          <Close
            fontSize="small"
            style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
            onClick={handleClose}
          />
          <DialogContentText style={{ marginTop: '1rem' }}>
            <Box style={{ paddingTop: '40px', paddingBottom: '20px' }} sx={{ width: '100%', textAlign: 'center' }}>
              <TitleTextGridContainer item>
                <PrimaryTitle>
                  {title}
                </PrimaryTitle>
                <SecondaryTitle>
                  {discreption}
                </SecondaryTitle>
              </TitleTextGridContainer>
            </Box>
          </DialogContentText>
          <Grid item container style={{ marginBottom: '1.2rem' }}>
            {topFields.map((field) => (
              <Grid item key={field?.name} xs={12}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
          <DialogActions style={{ marginBottom: '2rem' }}>
            <ButtonsGrid item container>
              <Button
                variant="contained"
                fullWidth
                style={{ width: '100%' }}
                onClick={handleSyncStore}
                disabled={loadingLinkMP}
              >
                {loadingLinkMP ? 'In Progress' : 'Process'}
              </Button>
            </ButtonsGrid>
          </DialogActions>
        </DialogContent>
      </CDialog>
      <ConfirmDialog
        openConfirm={open}
        handleCloseConfirm={toggleDialog}
        title="POS linked successfully!"
        discreption="Your OGApharmacy POS has been successfully linked"
        disc="to your OGApharmacy marketplace."
      />
    </>
  );
};

ProcessMarketplaceDialog.propTypes = {
  openConfirmation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  discreption: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default ProcessMarketplaceDialog;
