import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, Title,
  LineElement, Legend, Tooltip, LineController, BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import StoresPopper from './storesPopper';
import RangePopper from './rangePopper';
import { abbreviateNumber } from '../shared/utils';
import ChartLoader from './chartLoader';
import {
  WrapperGrid, ChartPaper, ChartGrid, GridTypo, ChartsTitle, InputGrid
} from './charts.styles';

const Charts = ({
  response, filter, handleFilter, businessAndSiblings
}) => {
  const [storesEl, setStoresEl] = useState(null);
  const [rangeEl, setRangeEl] = useState(null);

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController
  );

  const fontStyle = { size: '18px', weight: 'bolder', color: '#000000' };

  const { range, store } = filter;

  const returnStoreValues = () => {
    const vals = Array.isArray(store) ? store?.map((v) => `${v} `) : store;
    return vals;
  };

  const { data, loading } = response || {};
  const { storesSaleAnalyticsGraph } = data || {};
  const {
    totalSalesByCountGraph,
    totalSalesByMarginGraph,
    totalSalesValueVsCostOfGoodsSoldGraph
  } = storesSaleAnalyticsGraph || {};

  // const salesValues = totalSalesValueVsCostOfGoodsSoldGraph?.map((obj) => +obj.sales_value);
  // const COGS = totalSalesValueVsCostOfGoodsSoldGraph?.map((obj) => +obj.cost_of_goods_sold);

  // const salesByCount = totalSalesByCountGraph?.map((value) => +value.total);

  // const salesByMargin = totalSalesByMarginGraph?.map((value) => +value.total);

  // const weeks = totalSalesValueVsCostOfGoodsSoldGraph?.map((obj) => +obj.week);

  // const totalGoodCost = totalCostOfGoodsGraph?.map((value) => +value.total);
  // const totalValueMonth = totalOrderValueAffiliateGraph?.map(
  //   (value) => value.count
  // );

  const labels = [
    'Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'
  ];

  // Initialize an object with 0 for all months
  const monthlyCOGS = labels.reduce((acc, month) => {
    acc[month] = 0;
    return acc;
  }, {});

  const monthlySales = labels.reduce((acc, month) => {
    acc[month] = 0;
    return acc;
  }, {});

  const monthlyCount = labels.reduce((acc, month) => {
    acc[month] = 0;
    return acc;
  }, {});

  const monthlyMargin = labels.reduce((acc, month) => {
    acc[month] = 0;
    return acc;
  }, {});

  // Fill the object with actual data
  totalSalesValueVsCostOfGoodsSoldGraph?.forEach((obj) => {
    const monthIndex = +obj.month - 1; // Assuming 'month' is a numeric field (1-12)
    if (monthIndex >= 0 && monthIndex < 12) {
      monthlyCOGS[labels[monthIndex]] = +obj.cost_of_goods_sold;
      monthlySales[labels[monthIndex]] = +obj.sales_value;
    }
  });

  // Fill the object with actual data
  totalSalesByCountGraph?.forEach((value) => {
    const monthIndex = +value.month - 1; // Assuming 'month' is a numeric field (1-12)
    if (monthIndex >= 0 && monthIndex < 12) {
      monthlyCount[labels[monthIndex]] = +value.total;
    }
  });

  totalSalesByMarginGraph?.forEach((value) => {
    const monthIndex = +value.month - 1; // Assuming 'month' is a numeric field (1-12)
    if (monthIndex >= 0 && monthIndex < 12) {
      monthlyMargin[labels[monthIndex]] = +value.total;
    }
  });

  const salesByCount = range === 'week'
    ? totalSalesByCountGraph?.map((value) => +value.total)
    : Object.values(monthlyCount).map((value) => value || 0);
  const salesValues = range === 'week'
    ? totalSalesValueVsCostOfGoodsSoldGraph?.map((obj) => +obj.sales_value)
    : Object.values(monthlySales).map((value) => value || 0);
  const COGS = range === 'week'
    ? totalSalesValueVsCostOfGoodsSoldGraph?.map((obj) => +obj.cost_of_goods_sold)
    : Object.values(monthlyCOGS).map((value) => value || 0);
  const salesByMargin = range === 'week'
    ? totalSalesByMarginGraph?.map((value) => +value.total)
    : Object.values(monthlyMargin).map((value) => value || 0);

  // const weekLabel = [];
  // weeks?.forEach((week) => weekLabel.push(`Week ${week}`));

  // const returnPeriod = () => {
  //   switch (range) {
  //     case 'week':
  //       return weekLabel;
  //     default:
  //       return labels;
  //   }
  // };

  const returnPeriod = () => {
    const weeks = totalSalesValueVsCostOfGoodsSoldGraph?.map((obj) => +obj.week);
    switch (range) {
      case 'week':
        return weeks?.map((week) => `Week ${week}`);
      default:
        return labels;
    }
  };


  const salesData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'line',
        label: 'Cost of Goods Sold',
        backgroundColor: 'rgba(239,93,168, 1)',
        borderColor: 'rgba(239,93,168, 1)',
        data: COGS,
        yAxisID: 'y1',
        tension: 0
      },
      {
        type: 'bar',
        label: 'Sales By Value',
        data: salesValues,
        backgroundColor: 'rgba(77,166,255, 1)',
        borderColor: 'rgba(77,166,255, 1)',
        yAxisID: 'y',
        tension: 0.5
      }
    ]
  };

  const countData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'bar',
        label: 'Counts',
        data: salesByCount,
        backgroundColor: 'rgba(77,166,255, 1)',
        borderColor: 'rgba(77,166,255, 1)',
        tension: 0.5
      },
    ]
  };

  const marginData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'bar',
        label: '%Margin',
        data: salesByMargin,
        backgroundColor: 'rgba(77,166,255, 1)',
        borderColor: 'rgba(77,166,255, 1)',
        tension: 0.5
      },
    ]
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Sales By Value & Cost of Goods Sold',
        align: 'start',
        font: fontStyle
      },
      legend: {
        position: 'bottom'
      },
    },
    scales: {
      x: {
        grid: { display: false }
      },
      y: {
        title: { display: true, text: 'Sales By Value', font: fontStyle },
        ticks: {
          stepSize: range === 'month' ? 2500000 : 1500000,
          beginAtZero: true,
          callback(value) {
            return `₦${abbreviateNumber(value)}`;
          }
        }
      },
      y1: {
        title: { display: true, text: 'Cost of Goods Sold', font: fontStyle },
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: range === 'month' ? 2500000 : 1500000,
          callback(value) {
            return `₦${abbreviateNumber(value)}`;
          }
        }
      }
    }
  };

  const countOptions = JSON.parse(JSON.stringify(options));
  countOptions.plugins.legend = { position: 'top', align: 'end' };
  countOptions.plugins.title.text = 'Sales by Count';
  countOptions.scales = {
    x: {
      title: { display: true, text: 'Period', font: fontStyle },
      grid: { display: false }
    },
    y: {
      title: { display: true, text: 'Counts', font: fontStyle },
      ticks: {
        stepSize: range === 'month' ? 10000 : 5000,
        beginAtZero: true,
        callback(value) {
          return abbreviateNumber(value);
        }
      }
    },
  };

  const marginOptions = JSON.parse(JSON.stringify(options));
  marginOptions.plugins.legend = { position: 'top', align: 'end' };
  marginOptions.plugins.title.text = 'Gross Margin Sold';
  marginOptions.scales = {
    x: {
      title: { display: true, text: 'Period', font: fontStyle },
      grid: { display: false }
    },
    y: {
      title: { display: true, text: '% Value', font: fontStyle },
      ticks: {
        stepSize: range === 'month' ? 10 : 5,
        beginAtZero: true,
      }
    },
  };

  return (
    <>
      <WrapperGrid container>
        <ChartsTitle>Store Sales Analytics</ChartsTitle>
        <Grid item container xs={6} justifyContent="flex-end">
          <InputGrid
            item
            container
            xs={3}
            onClick={(e) => setStoresEl(e.currentTarget)}
            data-testid="stores-grid"
          >
            {returnStoreValues()}
            <KeyboardArrowDownRounded style={{ fontSize: '1.5rem', color: '#606060' }} />
          </InputGrid>
          <InputGrid
            item
            container
            xs={3}
            onClick={(e) => setRangeEl(e.currentTarget)}
            data-testid="range-grid"
          >
            {`${range}ly`}
            <KeyboardArrowDownRounded style={{ fontSize: '1.5rem', color: '#606060' }} />
          </InputGrid>
        </Grid>
      </WrapperGrid>

      <ChartPaper elevation={0}>
        <ChartGrid item container range={range} loading={loading}>
          {!loading ? <Chart data={salesData} options={options} /> : <ChartLoader />}
        </ChartGrid>
      </ChartPaper>

      <ChartPaper elevation={0}>
        <ChartGrid item container range={range} loading={loading}>
          {!loading ? <Chart data={countData} options={countOptions} /> : <ChartLoader />}
        </ChartGrid>
      </ChartPaper>
      <ChartPaper elevation={0}>
        <ChartGrid item container range={range} loading={loading}>
          {!loading ? <Chart data={marginData} options={marginOptions} /> : <ChartLoader />}
        </ChartGrid>
      </ChartPaper>

      <StoresPopper
        storesEl={storesEl}
        setStoresEl={setStoresEl}
        businessAndSiblings={businessAndSiblings}
        filter={filter}
        handleFilter={handleFilter}
      />

      <RangePopper
        rangeEl={rangeEl}
        setRangeEl={setRangeEl}
        filter={filter}
        handleFilter={handleFilter}
      />
    </>
  );
};

Charts.propTypes = {
  response: PropTypes.shape(Object).isRequired,
  filter: PropTypes.string.isRequired,
  handleFilter: PropTypes.func.isRequired,
  businessAndSiblings: PropTypes.shape(Array).isRequired
};

export default Charts;
