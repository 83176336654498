import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

export const SwitchStyled = styled(Switch)`
  &.MuiSwitch-root {
    width: 100%;
    height: 100%;
    padding: 0px;
    position: relative;
  }

  & .MuiSwitch-switchBase {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 5px;
      padding-right: 0px;
      // padding: 8px 5px;
    
      @media (min-width: 1700px) {
        padding-left: 16px;
      }

      & .MuiSwitch-checked {
        &track {
          background-color: #FFF;
        }
      }
    }

  & .MuiSwitch-thumb {
    color: #235A91;
    display: flex;
    width: 65px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 1px;

    &:before {
      content: ${({ sliderValue }) => `"${sliderValue}"`};
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
    }
  }

  & .MuiSwitch-track {
    border: 1px solid #78AADA;
    border-radius: 10px;
    background-color: #ECF6FF !important;
    opacity: 1 !important;

    &:after, &:before {
      color: #606060;
      font-size: 12px;
      font-weight: 500;
      position: absolute;
      top: 1rem;
    }

    &:after {
      content: "Graph";
      left: 1.2rem;
    }

    &:before {
      content: "Data";
      right: 1.6rem;
    }
  }

  & .Mui-checked {
    color: #23bf58 !important;
    transform: translateX(71px) !important;
    padding-left: 0px;
    @media (min-width: 1400px) {
      margin-left: -4.5px;
    }
    @media (min-width: 1500px) {
      margin-left: 0px;
    }
      @media (min-width: 1600px) {
      margin-left: 1rem;
    }
    @media (min-width: 1800px) {
      margin-left: 1.8rem;
    }
`;
